<template>
  <BaseContainer>
    <AssessmentAnalytics />
  </BaseContainer>
</template>

<script>
import AssessmentAnalytics from '@components/ClientAssessments/AssessmentAnalytics'

export default {
  components: {
    AssessmentAnalytics
  }
}
</script>
